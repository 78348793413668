import { useRouter } from 'next/router';
import qs from 'query-string';
import { useEffect, useState } from 'react';

/**
 * Retrieves query params as they would appear in
 * the browser's address bar, rather than the internal
 * Next.js query, which may include URL params.
 *
 * e.g. `{ sort: "Alphabetical" }` rather than
 * `{ page: 1, sort: "Alphabetical" }` when parsing
 * "/artists/1?sort=Alphabetical
 *
 * @returns {object} the current query params
 */
export const useQueryParams = (): object => {
  const router = useRouter();
  const [path, setPath] = useState(router.asPath);

  useEffect(() => {
    const listener = (url: string) => {
      setPath(url);
    };

    router.events.on('routeChangeComplete', listener);
    return () => {
      router.events.off('routeChangeComplete', listener);
    };
  }, []);

  return qs.parse(qs.extract(path));
};
