import { generalSubscriptionsInput } from '../../../constants/subscriptions';
import { Step, StepAnalytics } from '../../../providers';
import { GeneratedCreateSubscriptionsInput } from '../../../types/generated';
import {
  AuthFooterContainer,
  AuthMethodContainer,
  AuthTitleContainer,
  EmailContainer,
  EmailTitleContainer,
  VerifyContainer,
} from '../containers';
import { AuthAnalytics } from '../types';
// eslint-disable-next-line import/no-cycle
import { buildSignUpFlow } from './buildSignUpFlow';

const EMAIL_STEP = 'email';
const VERIFY_STEP = 'verify';

/**
 * Builds a sign in flow based on the provided config
 *
 * @param config.properties Optional analytics properties to send throughout flow
 * @param config.subscriptions Optional subscriptions to subscribe user to
 * @param config.showPhoneStep Optional boolean to show phone step in flow
 * @param config.optInGeneral Optional boolean to automatically opt user into general subscription
 * @returns An array of steps in the sign in flow
 */
export const buildSignInFlow = (config?: {
  properties?: StepAnalytics['properties'];
  showPhoneStep?: boolean;
  subscriptions?: GeneratedCreateSubscriptionsInput[];
  optInGeneral?: boolean;
  skipProfileStep?: boolean;
  hideSuccessScreen?: boolean;
  hideCloseButton?: boolean;
}): Step[] => {
  const {
    hideCloseButton = false,
    optInGeneral = true,
    properties,
    showPhoneStep = false,
    subscriptions,
  } = config ?? {};

  const swapFlowFooter = (
    <AuthFooterContainer buildCtaFlowSteps={() => buildSignUpFlow(config)} />
  );

  return [
    {
      id: 'authMethod',
      content: (
        <AuthMethodContainer
          optInGeneral={optInGeneral}
          showPhoneStep={showPhoneStep}
          subscriptions={subscriptions ?? [generalSubscriptionsInput]}
        />
      ),
      title: <AuthTitleContainer />,
      footer: swapFlowFooter,
      analytics: {
        name: AuthAnalytics.SignIn,
        properties,
      },
      hideCloseButton,
    },
    {
      id: EMAIL_STEP,
      content: (
        <EmailContainer stepId={EMAIL_STEP} subscriptions={subscriptions} />
      ),
      title: <EmailTitleContainer />,
      footer: swapFlowFooter,
      analytics: {
        name: AuthAnalytics.SignInEmail,
        properties,
      },
      hideCloseButton,
    },
    {
      id: VERIFY_STEP,
      content: (
        <VerifyContainer
          optInGeneral={optInGeneral}
          showPhoneStep={showPhoneStep}
          stepId={VERIFY_STEP}
        />
      ),
      title: 'Verify with code',
      analytics: {
        name: AuthAnalytics.SignInCode,
        properties,
      },
      hideCloseButton,
    },
  ];
};
