export enum AuthAnalytics {
  SignUp = 'create account',
  SignUpEmail = 'create account email',
  SignUpCode = 'create account code',
  SignUpGoogle = 'create account google',
  SignUpPhone = 'create account add phone number',
  SignUpProfile = 'create account profile',
  SignUpConfirmation = 'create account confirmation',
  SignIn = 'sign in',
  SignInEmail = 'sign in email',
  SignInGoogle = 'sign in google',
  SignInCode = 'sign in code',
  SignInPhone = 'sign in add phone number',
  SignInProfile = 'sign in profile',
}

export enum AuthSource {
  Artist = 'artist',
  Product = 'product',
  DrawEntry = 'draw entry',
  NftPrint = 'nft print',
  Event = 'event',
  NavBar = 'nav bar',
  NewsletterFooter = 'newsletter footer',
  NewsletterInsights = 'newsletter insights',
  NewsletterHomepage = 'newsletter homepage',
}
