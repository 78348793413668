import { ApolloError } from '@apollo/client';
import { Alert } from '@design-system';
import { useTranslation } from 'next-i18next';
import { FormEvent } from 'react';

import {
  isLoginCodeExpiredError,
  isLoginCodeInvalidError,
  isRateLimitError,
} from '../../../utils/errors';

export const ApiError: React.FC<{
  apiError: ApolloError;
  retry: (() => Promise<void>) | undefined;
}> = ({ apiError, retry }) => {
  const { t } = useTranslation('authentication');

  const handleRetry = (e: FormEvent<HTMLButtonElement>) => {
    if (!retry) return;

    e.preventDefault();
    retry();
  };

  if (isLoginCodeExpiredError(apiError)) {
    return (
      <Alert $status="error">
        {t('errors.expired')}{' '}
        <button
          className="inline underline hover:no-underline cursor-pointer"
          onClick={handleRetry}
          type="button"
        >
          {t('errors.requestNew')}
        </button>
      </Alert>
    );
  }

  if (isRateLimitError(apiError)) {
    return <Alert $status="error">{t('errors.rateLimit')}</Alert>;
  }

  if (isLoginCodeInvalidError(apiError)) {
    return (
      <Alert $status="error">
        {t('errors.invalid')}
        <button
          className="inline underline hover:no-underline cursor-pointer"
          onClick={handleRetry}
          type="button"
        >
          {t('errors.requestNew')}
        </button>
      </Alert>
    );
  }

  return <Alert $status="error">{t('errors.generic')} </Alert>;
};
