import { MediumFilter } from '../types/Filters';

type EmailReference = { email: 'SUPPORT' } | { email: 'COLLECTING' };

type PageReference =
  | { page: 'ACCOUNT' }
  | { page: 'SIGN_UP' }
  | { page: 'ARTISTS_INDEX'; pageNumber?: number }
  | { page: 'ARTISTS_INDEX_ROOT_PATH' }
  | { page: 'STORIES_PAGINATION_ROOT_PATH' }
  | { page: 'SUPPORT'; slug: string }
  | { page: 'TERMS_AND_CONDITIONS' }
  | { page: 'SERIES'; slug: string }
  | { page: 'WORK'; slug: string; release?: string }
  | {
      page: 'WORKS_INDEX';
      pageNumber?: number;
      filters?: {
        medium: MediumFilter;
      };
    }
  | { page: 'WORKS_PAGINATION_ROOT_PATH' }
  | { page: 'CONTACT_US' }
  | { page: 'ABOUT' }
  | { page: 'GIFT_CARD'; email?: string }
  | { page: 'NEW_SIGNIN' }
  | { page: 'NEW_SIGNUP' }
  | { page: 'PRIVACY_POLICY' }
  | { page: 'ACCOUNT_ADDRESSES' }
  | { page: 'ACCOUNT_DETAILS' }
  | { page: 'ACCOUNT_DRAW_ENTRIES' }
  | { page: 'ACCOUNT_ONBOARDING' }
  | { page: 'ACCOUNT_ORDERS' }
  | { page: 'ACCOUNT_PAYMENT_METHODS' }
  | { page: 'ACCOUNT_OVERVIEW' }
  | { page: 'INSIGHTS_INDEX' }
  | { page: 'RESALE_AGREEMENT'; queryParams: string }
  | { page: 'ARTIST'; slug?: string }
  | { page: 'FRAMING_GUIDE' }
  | { page: 'PRIVATE_LINK'; slug: string }
  | { page: 'INSIGHTS_INDEX' }
  | { page: 'GLOSSARY_INDEX' }
  | {
      city: string;
      country: string;
      page: 'FRAMER';
      slug: string;
    }
  | {
      country: string;
      page: 'FRAMING_COUNTRY_SERVICES';
      slug: string;
    }
  | {
      city: string;
      country: string;
      page: 'FRAMING_CITY_SERVICES';
      slug: string;
    };

export const pathFor = (ref: PageReference): string => {
  // This is tech debt, only required for the way we're creating pagination URLs
  if (ref.page === 'ARTISTS_INDEX_ROOT_PATH') return '/artists';
  if (ref.page === 'STORIES_PAGINATION_ROOT_PATH') return '/stories';
  if (ref.page === 'WORKS_PAGINATION_ROOT_PATH') return '/products';

  if (ref.page === 'WORK')
    return ref.release
      ? `/products/${ref.slug}?release=${ref.release.toLowerCase()}`
      : `/products/${ref.slug}`;

  if (ref.page === 'WORKS_INDEX') {
    return ref.filters?.medium
      ? `/products/${ref.pageNumber || 1}?medium=${ref.filters.medium.replace(
          /\s/g,
          '+',
        )}`
      : `/products/${ref.pageNumber || 1}`;
  }

  if (ref.page === 'SERIES') return `/series/${ref.slug}`;
  if (ref.page === 'ACCOUNT') return 'https://store.avantarte.com/account';
  if (ref.page === 'ARTISTS_INDEX') return `/artists/${ref.pageNumber || 1}`;
  if (ref.page === 'SUPPORT') return `/support/${ref.slug}`;
  if (ref.page === 'SIGN_UP')
    return 'https://store.avantarte.com/account/register';
  if (ref.page === 'TERMS_AND_CONDITIONS')
    return '/support/terms-and-conditions';
  if (ref.page === 'GIFT_CARD') return '/gift-cards';
  if (ref.page === 'NEW_SIGNIN') return '/sign-in';
  if (ref.page === 'NEW_SIGNUP') return '/sign-up';
  if (ref.page === 'ACCOUNT_OVERVIEW') return '/profile';
  if (ref.page === 'ACCOUNT_ADDRESSES') return '/profile/addresses';
  if (ref.page === 'ACCOUNT_ONBOARDING') return '/profile/interests';
  if (ref.page === 'ACCOUNT_ORDERS') return '/profile/orders';
  if (ref.page === 'ACCOUNT_PAYMENT_METHODS') return '/profile/payment-methods';
  if (ref.page === 'ACCOUNT_DETAILS') return '/profile/details';
  if (ref.page === 'ACCOUNT_DRAW_ENTRIES') return '/profile/draw-entries';
  if (ref.page === 'PRIVACY_POLICY') return '/support/privacy-policy';
  if (ref.page === 'ARTIST') return `/artists/${ref.slug}`;
  if (ref.page === 'ABOUT') return `/about`;
  if (ref.page === 'RESALE_AGREEMENT')
    return `${process.env.NEXT_PUBLIC_RESALE_AGREEMENT}`;
  if (ref.page === 'FRAMING_GUIDE') return '/framing';
  if (ref.page === 'PRIVATE_LINK') return `/private/${ref.slug}`;
  if (ref.page === 'INSIGHTS_INDEX') return '/insights/1';
  if (ref.page === 'GLOSSARY_INDEX') return '/glossary';
  if (ref.page === 'FRAMING_COUNTRY_SERVICES') {
    return `/framing/${ref.country}/services/${ref.slug}`;
  }
  if (ref.page === 'FRAMING_CITY_SERVICES') {
    return `/framing/${ref.country}/${ref.city}/services/${ref.slug}`;
  }
  if (ref.page === 'FRAMER') {
    return `/framing/${ref.country}/${ref.city}/${ref.slug}`;
  }
  return '/';
};

export const urlFor = (ref: PageReference): string => {
  const path = pathFor(ref);
  try {
    new URL(path);
    return path;
  } catch (e) {
    return new URL(path, process.env.NEXT_PUBLIC_HOSTNAME!).toString();
  }
};

export const emailFor = (ref: EmailReference): string => {
  if (ref.email === 'COLLECTING') return 'mailto:collecting@avantarte.com';
  return 'mailto:support@avantarte.com';
};

export const domain = (url: string) => {
  try {
    const parsed = new URL(url);
    return parsed.hostname;
  } catch (_e) {
    return null;
  }
};

export const relativePath = (url: string) => {
  try {
    const parsed = new URL(url);
    return parsed.pathname + parsed.search + parsed.hash;
  } catch (_e) {
    return null;
  }
};

export const toCanonicalUrl = (options: {
  path: string;
  hostname: string;
  locale?: string;
}) => {
  const url = new URL(
    stripLocalisation(options.path, options.locale),
    options.hostname,
  );

  url.search = '';
  return url.toString();
};

export const toLocalisedCanonicalUrl = ({
  path = '',
  hostname = process.env.NEXT_PUBLIC_HOSTNAME!,
  locale,
}: Record<string, string>) => {
  if (locale === 'en') {
    return toCanonicalUrl({ path, hostname, locale });
  }
  const url = new URL(`${locale}${path}`, hostname);
  url.search = '';
  return url.toString();
};

export const stripLocalisation = (pathname: string, locale: string = 'en') => {
  const regex = new RegExp(`^/?${locale}/`);
  return pathname.replace(regex, '');
};

export const isValidUrl = (url: string) => {
  try {
    const parsed = new URL(url);
    return ['http:', 'https:'].includes(parsed.protocol);
  } catch (e) {
    return false;
  }
};

export const relForUrl = (url = '') => {
  // set rel=nofollow for private rooms
  if (url?.startsWith(`${process.env.NEXT_PUBLIC_HOSTNAME}private/`)) {
    return 'nofollow';
  }
  return undefined;
};
