import React, { FC } from 'react';

interface Props {
  text: string;
  ctaText: string;
  onClick: () => void;
}

export const AuthFooter: FC<Props> = ({ ctaText, onClick, text }) => (
  <div className="md:rounded-b-lg bg-neutral-1 py-4 text-center">
    {text}&nbsp;
    <span
      className="underline hover:no-underline"
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter') onClick();
      }}
      role="button"
      tabIndex={0}
    >
      {ctaText}
    </span>
  </div>
);
