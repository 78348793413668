import { ApolloError } from '@apollo/client';
import { Button, Select } from '@design-system';
import { useTranslation } from 'next-i18next';
import React, {
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Checkbox, InputField } from '../../../components/Forms';
import { DateOfBirth } from '../../../components/Forms/DateOfBirth';
import { PronounOptions } from '../../../constants/account';
import { COUNTRY_CODES } from '../../../constants/locations';
import UserPreferencesContext from '../../../context/UserPreferencesContext';
import { useCountryCode } from '../../../hooks/useCountryCode';
import { useFlow } from '../../../providers';
import {
  GeneratedPronouns,
  useUpdateAccountMutation,
} from '../../../types/generated';
import { useAnalyticsEmitter } from '../../../utils/analytics/emitter';
import { extractApolloError } from '../../../utils/errors';
import { normalizeDateOfBirth } from '../../../utils/profile';
import { ApiError } from '../components';
import {
  profileFormResolver,
  ProfileFormValues,
} from '../utils/profileFormResolver';

interface Props {
  optInGeneral: boolean;
  isSubscribedGeneral: boolean;
  onSubscribe: () => Promise<void>;
  loading: boolean;
}

const generateRandomUsername = (fullName: string): string =>
  `${fullName.split(' ')[0].toLowerCase()}${Math.floor(Math.random() * 10000)}`;

export const ProfileFormContainer: React.FC<Props> = ({
  isSubscribedGeneral,
  loading,
  onSubscribe,
  optInGeneral,
}) => {
  const { t } = useTranslation(['authentication', 'countries']);
  const { goNextStep } = useFlow();
  const [emitter] = useAnalyticsEmitter();
  const { setCountry } = useContext(UserPreferencesContext);
  const [updateAccount] = useUpdateAccountMutation();

  const [apiError, setApiError] = useState<ApolloError | null>(null);
  const [countryCode] = useCountryCode();

  const form = useForm<ProfileFormValues>({
    defaultValues: {
      pronouns: GeneratedPronouns.PronounsRatherNotSay,
      subscribeToGeneral: optInGeneral ? true : !isSubscribedGeneral,
      fullName: '',
      countryCode,
      day: '',
      month: '',
      year: '',
    },
    resolver: profileFormResolver,
  });

  useEffect(() => {
    if (!isSubscribedGeneral) {
      // If the user is not subscribed to general, we want the checkbox to be checked by default
      form.setValue('subscribeToGeneral', true, { shouldValidate: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubscribedGeneral]);

  const validation = form.formState.errors ?? {};
  const values = form.getValues();

  const nameError = validation.fullName?.message;
  const dayError = validation.day?.message;
  const monthError = validation.month?.message;
  const yearError = validation.year?.message;
  const dateOfBirthError = yearError ?? dayError ?? monthError;

  useEffect(() => {
    // Send any validation errors to analytics
    if (nameError) emitter('', 'error', { error: t(nameError) });
    if (dateOfBirthError) emitter('', 'error', { error: t(dateOfBirthError) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameError, dateOfBirthError]);

  const handleSubmit = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      form.handleSubmit(async (formValues: ProfileFormValues) => {
        ev.preventDefault();
        emitter('submit', 'clicked');

        try {
          if (formValues.subscribeToGeneral) {
            onSubscribe();
          }

          const dateOfBirth = normalizeDateOfBirth(
            formValues.day,
            formValues.month,
            formValues.year,
          );

          const { data, errors } = await updateAccount({
            variables: {
              name: formValues.fullName,
              countryCode: formValues.countryCode,
              pronouns: formValues.pronouns,
              dateOfBirth,
              username: generateRandomUsername(formValues.fullName),
            },
          });

          const error = extractApolloError(errors);

          if (error) {
            emitter('', 'error', { error });
          }

          if (data?.updateAccount?.id) {
            setCountry(formValues.countryCode);
            emitter('', 'success');
            goNextStep();
          }
        } catch (err) {
          setApiError(err as ApolloError);
        }
      })(ev);
    },
    [emitter, goNextStep, form, onSubscribe, updateAccount, setCountry],
  );

  const pronounItems = PronounOptions.map((pronoun: GeneratedPronouns) => ({
    key: t(`screens.profile.fields.pronouns.options.${pronoun}`),
    value: pronoun,
  }));

  const countryCodeOptions = COUNTRY_CODES.map((code) => ({
    key: t(`countries:${code}`),
    value: code,
  }));

  const showOptInCheckbox = !loading && !optInGeneral && !isSubscribedGeneral;

  return (
    <FormProvider {...form}>
      <form noValidate onSubmit={handleSubmit}>
        {apiError ? (
          <div className="mb-4">
            <ApiError apiError={apiError} retry={undefined} />
          </div>
        ) : null}

        <InputField
          {...form.register('fullName')}
          className="mb-4"
          id="fullName"
          label={t('screens.profile.fields.fullName.label')}
          name="fullName"
          warning={nameError ? t(nameError) : undefined}
        />

        <Select
          {...form.register('pronouns', { required: true })}
          $label={t('screens.profile.fields.pronouns.label')}
          $options={pronounItems}
          className="mb-4"
          id="pronouns"
        />

        <DateOfBirth />

        <p className="text-neutral-4 text-xs mb-4">
          {t('screens.profile.fields.birthday.disclaimer')}
        </p>

        <Select
          {...form.register('countryCode', { required: true })}
          $label={t('screens.profile.fields.countryCode.label')}
          $options={countryCodeOptions}
          className="mb-6"
          id="countryCode"
        />

        {showOptInCheckbox && (
          <Checkbox
            {...form.register('subscribeToGeneral')}
            checked={values.subscribeToGeneral}
            className="mb-6"
            label={t('screens.profile.fields.subscribeToGeneral.label')}
            name="subscribeToGeneral"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              form.setValue('subscribeToGeneral', e.target.checked, {
                shouldValidate: true,
              })
            }
          />
        )}

        <Button
          fullWidth
          label={t('screens.profile.saveBtn')}
          size="lg"
          type="submit"
          variant="primary"
        />
      </form>
    </FormProvider>
  );
};
