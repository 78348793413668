import { useContext } from 'react';
import UserPreferencesContext from '../context/UserPreferencesContext';
import { CountryCode } from '../types/Locations';

export interface CountryCodeSetter {
  (countryCode: CountryCode): void;
}

export function useCountryCode(): [CountryCode, CountryCodeSetter] {
  const { preferences, setCountry } = useContext(UserPreferencesContext);

  return [preferences.countryCode, setCountry];
}
