export enum AuthFlow {
  SignUp = 'signUp',
  SignIn = 'signIn',
  SignInFrameUpgrade = 'signInFrameUpgrade',
  PhoneOnly = 'phone',
  ProfileOnly = 'profile',
}

export enum AuthEntryPoint {
  AuthPage = 'auth page',
  Artist = 'artist',
  DrawEntry = 'draw entry',
  Event = 'event',
  Release = 'release page',
  NavBar = 'nav bar',
  NewsletterFooter = 'newsletter footer',
  NewsletterHomepage = 'newsletter homepage',
  NewsletterInsights = 'newsletter insights',
  NewsletterFraming = 'newsletter framing guide',
  NftWallet = 'nft wallet',
  PreOrder = 'pre-order',
  Product = 'product',
  AML = 'aml verification page',
}
