import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import { InputField } from './InputField/InputField';

interface DateOfBirthFormValues {
  day: string;
  month: string;
  year: string;
}

export const DateOfBirth: FC = () => {
  const { t } = useTranslation('authentication');

  const form = useFormContext<DateOfBirthFormValues>();

  const validation = form.formState.errors ?? {};

  const dayError = validation.day?.message as string;
  const monthError = validation.month?.message;
  const yearError = validation.year?.message;
  const dateOfBirthError = yearError ?? dayError ?? monthError;

  return (
    <div className="w-full flex flex-col gap-2">
      <p className="w-full text-xs">
        {t('screens.profile.fields.birthday.label')}
      </p>
      <div className="w-full grid grid-cols-3 gap-3">
        <InputField
          {...form.register('day', { deps: ['month', 'year'] })}
          id="day"
          max={31}
          maxLength={2}
          min={1}
          name="day"
          placeholder={t('screens.profile.fields.birthday.dayPlaceholder')}
          showWarningMessage={false}
          type="number"
          warning={dayError}
        />

        <InputField
          {...form.register('month', { deps: ['day', 'year'] })}
          id="month"
          max={12}
          maxLength={2}
          min={1}
          name="month"
          placeholder={t('screens.profile.fields.birthday.monthPlaceholder')}
          showWarningMessage={false}
          type="number"
          warning={monthError}
        />

        <InputField
          {...form.register('year', { deps: ['day', 'month'] })}
          id="year"
          max={new Date().getFullYear()}
          maxLength={4}
          min={1920}
          name="year"
          placeholder={t('screens.profile.fields.birthday.yearPlaceholder')}
          showWarningMessage={false}
          type="number"
          warning={yearError}
        />
      </div>
      {dateOfBirthError ? (
        <p className="w-full text-danger-4 text-xs">{t(dateOfBirthError)}</p>
      ) : null}
    </div>
  );
};
