import classNames from 'classnames';
import { ChangeEvent, forwardRef, useState } from 'react';

import { CheckInput, CheckInputProps } from '../CheckInput';
import { FormWarning } from '../FormWarning';

export interface RadioProps extends CheckInputProps {
  className?: string;
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const { className, onBlur, onChange, onFocus, ...rest } = props;
  const [focus, setFocus] = useState(false);

  return (
    <div className={classNames(className)}>
      <CheckInput
        {...rest}
        ref={ref}
        focus={focus}
        onBlur={(ev: ChangeEvent<HTMLInputElement>) => {
          setFocus(false);
          if (onBlur) onBlur(ev);
        }}
        onChange={(ev: ChangeEvent<HTMLInputElement>) => void onChange?.(ev)}
        onFocus={(ev: ChangeEvent<HTMLInputElement>) => {
          setFocus(true);
          if (onFocus) onFocus(ev);
        }}
        variant="radio"
      />
      <FormWarning message={props.warning} />
    </div>
  );
});
