import { Button, Icon } from '@design-system';
import { Trans, useTranslation } from 'next-i18next';
import React from 'react';

import { Image } from '../../../components/Image';
import UnderlinedLink from '../../../components/UnderlinedLink';
import { pathFor } from '../../../helpers/links';
import { useQueryParams } from '../../../hooks/useQueryParams';
import { useFlow } from '../../../providers';
import { GeneratedCreateSubscriptionsInput } from '../../../types/generated';
import { useAnalyticsEmitter } from '../../../utils/analytics/emitter';
import { AuthFlow } from '../types';
import { GoogleAuthContainer } from './GoogleAuthContainer';

interface Props {
  imageUrl?: string;
  hideSuccessScreen?: boolean;
  optInGeneral: boolean;
  showPhoneStep?: boolean;
  subscriptions: GeneratedCreateSubscriptionsInput[];
}

function inAppBrowser() {
  if (typeof navigator === 'undefined') return false;

  const ua = (navigator.userAgent || navigator.vendor || '').toLowerCase();

  return (
    ua.includes('instagram') ||
    ua.includes('facebook') ||
    ua.includes('fban') ||
    ua.includes('fbav') ||
    ua.includes('twitter') ||
    ua.includes('wechat') ||
    ua.includes('pinterest') ||
    ua.includes('tiktok') ||
    ua.includes('reddit') ||
    ua.includes('discord')
  );
}

export const AuthMethodContainer: React.FC<Props> = ({
  hideSuccessScreen = true,
  imageUrl,
  optInGeneral,
  showPhoneStep = false,
  subscriptions,
}) => {
  const { flowConfig, goNextStep } = useFlow();
  const queryParams: { email?: string } = useQueryParams();
  const [emitter] = useAnalyticsEmitter();
  const { t } = useTranslation('authentication');
  const shouldShowFrameUpgradeMessage =
    flowConfig?.name === AuthFlow.SignInFrameUpgrade && queryParams.email;

  return (
    <div>
      {shouldShowFrameUpgradeMessage ? (
        <p className="text-center mb-6">
          To check our bespoke framing options for your print, sign in with{' '}
          <strong>{queryParams.email}</strong>
        </p>
      ) : null}
      {imageUrl && (
        <div className="mx-auto w-24 h-24 md:w-32 md:h-32 mb-8">
          <Image
            alt="Sign up image"
            className="rounded-sm overflow-hidden"
            src={imageUrl}
          />
        </div>
      )}
      <div className="mb-3 relative">
        <Button
          fullWidth
          label={t('email')}
          onClick={() => {
            emitter('continue', 'clicked', { provider: 'email' });
            goNextStep();
          }}
          size="lg"
          variant="tertiary"
        />
        <Icon
          className="absolute top-1/2 -translate-y-1/2 left-6 select-none pointer-events-none"
          name="ic_mail"
        />
      </div>

      {!inAppBrowser() && (
        <div className="mb-3">
          <GoogleAuthContainer
            hideSuccessScreen={hideSuccessScreen}
            optInGeneral={optInGeneral}
            showPhoneStep={showPhoneStep}
            subscriptions={subscriptions}
          />
        </div>
      )}

      <p className="text-neutral-4 text-xs mx-8 text-center">
        {optInGeneral ? (
          <Trans i18nKey="screens.signUp.captionOptInGeneral" t={t}>
            By continuing, you’re agreeing to our&nbsp;
            <UnderlinedLink
              href={pathFor({ page: 'TERMS_AND_CONDITIONS' })}
              target="_blank"
            >
              terms &amp; conditions
            </UnderlinedLink>
            {' and '}
            <UnderlinedLink
              href={pathFor({ page: 'PRIVACY_POLICY' })}
              target="_blank"
            >
              privacy policy
            </UnderlinedLink>
            &nbsp;and opting in to receive updates and marketing emails.
          </Trans>
        ) : (
          <Trans i18nKey="screens.signUp.captionNoOptIn" t={t}>
            By continuing, you’re agreeing to our&nbsp;
            <UnderlinedLink
              href={pathFor({ page: 'TERMS_AND_CONDITIONS' })}
              target="_blank"
            >
              terms &amp; conditions
            </UnderlinedLink>
            {' and '}
            <UnderlinedLink
              href={pathFor({ page: 'PRIVACY_POLICY' })}
              target="_blank"
            >
              privacy policy
            </UnderlinedLink>
          </Trans>
        )}
      </p>
    </div>
  );
};
