import classNames from 'classnames';
import { ChangeEvent, forwardRef, useState } from 'react';

import { CheckInput, CheckInputProps } from '../CheckInput';
import { FormWarning } from '../FormWarning';

export interface CheckboxProps extends CheckInputProps {
  className?: string;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    const { className, onBlur, onFocus, ...rest } = props;
    const [focus, setFocus] = useState(false);

    return (
      <div className={classNames(className)}>
        <CheckInput
          {...rest}
          ref={ref}
          focus={focus}
          onBlur={(ev: ChangeEvent<HTMLInputElement>) => {
            setFocus(false);
            if (onBlur) onBlur(ev);
          }}
          onFocus={(ev: ChangeEvent<HTMLInputElement>) => {
            setFocus(true);
            if (onFocus) onFocus(ev);
          }}
          variant="checkbox"
        />
        <FormWarning message={props.warning} />
      </div>
    );
  },
);
