import React, { createContext, useEffect } from 'react';
import { useUnsafeInternalEmitter } from './emitter';
import { Map, PropsWithEmitterChild } from './types';

export interface AnalyticsObjectContext {
  name: string;
  properties?: Map;
  sendViewedEvent?: boolean;
}

interface AnalyticsObjectProviderProps extends AnalyticsObjectContext {}

export const AnalyticsObject = createContext<AnalyticsObjectContext | null>(
  null,
);

export const AnalyticsObjectProvider: React.FC<
  PropsWithEmitterChild<AnalyticsObjectProviderProps>
> = (props) => {
  const { children, name, properties, sendViewedEvent } = props;
  const [emitter] = useUnsafeInternalEmitter({
    objectOverride: { name, properties },
  });

  useEffect(() => {
    if (sendViewedEvent) emitter('', 'viewed');
  }, [name, sendViewedEvent]);

  return (
    <AnalyticsObject.Provider value={{ name, properties }}>
      {children(emitter)}
    </AnalyticsObject.Provider>
  );
};
