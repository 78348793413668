import classNames, { Argument } from 'classnames';
import { ChangeEvent, forwardRef, useState } from 'react';

import { CheckIcon } from '../CheckIcon';
import { FormWarning } from '../FormWarning';

export interface CheckButtonProps {
  className?: Argument;
  defaultChecked?: boolean;
  disabled?: boolean;
  disabledMessage?: string;
  id: string;
  isChecked?: boolean;
  label: string;
  onBlur?: (ev: ChangeEvent) => void;
  onChange?: (ev: ChangeEvent) => void;
  onFocus?: (ev: ChangeEvent) => void;
  name: string;
  required?: boolean;
  warning?: string;
  value?: HTMLInputElement['value'];
  variant: 'checkbox' | 'radio';
}

export const CheckButton = forwardRef<HTMLInputElement, CheckButtonProps>(
  (
    {
      className,
      defaultChecked = false,
      disabled = false,
      disabledMessage,
      id,
      isChecked = false,
      label,
      name,
      onBlur,
      onChange,
      onFocus,
      required = false,
      value,
      variant = 'checkbox',
      warning,
    },
    ref,
  ) => {
    const [focus, setFocus] = useState(false);
    const [checked, setChecked] = useState(defaultChecked);
    const active = isChecked !== undefined ? isChecked : checked;

    return (
      <div className={classNames(className)}>
        <label
          className={classNames(
            'border flex flex-1 items-center justify-between gap-3 rounded-sm',
            'pl-3 pr-2 py-2 md:pl-4 md:py-3',
            {
              'cursor-pointer': !disabled,
              'border-divider/20': !active && !focus && !warning,
              'border-neutral-4 ring-2 ring-divider/20': focus && !warning,
              'border-orange': Boolean(warning),
              'bg-neutral-1 cursor-not-allowed': disabled,
              'border-dark ring-1 ring-dark': active,
            },
          )}
          htmlFor={id}
        >
          <span className="truncate">{label}</span>
          <span className="flex flex-col items-center justify-center relative shrink-0 h-6 w-6">
            <input
              ref={ref}
              className="absolute opacity-0 peer h-6 w-6"
              defaultChecked={defaultChecked}
              disabled={disabled}
              id={id}
              name={name}
              onBlur={(ev: ChangeEvent<HTMLInputElement>) => {
                setFocus(false);
                if (onBlur) onBlur(ev);
              }}
              onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                setChecked(ev.target.checked);
                if (onChange) onChange(ev);
              }}
              onFocus={(ev: ChangeEvent<HTMLInputElement>) => {
                setFocus(true);
                if (onFocus) onFocus(ev);
              }}
              required={required}
              type={variant}
              value={value}
            />
            {active && (
              <CheckIcon
                checked={active}
                disabled={false}
                focus={false}
                shape={variant === 'checkbox' ? 'square' : 'circle'}
                warning={false}
              />
            )}
            {Boolean(!checked && disabled && disabledMessage) && (
              <span className="md:pr-2 pr-1 text-neutral-4">
                {disabledMessage}
              </span>
            )}
          </span>
        </label>
        <FormWarning message={warning} />
      </div>
    );
  },
);
