import classNames from 'classnames';
import { FC } from 'react';

interface FormWarningProps {
  className?: string;
  message?: string;
}

export const FormWarning: FC<FormWarningProps> = ({
  className = 'mt-1.5',
  message,
}) => {
  if (!message) {
    return null;
  }

  return (
    <p className={classNames('text-danger-4 text-xs', className)}>{message}</p>
  );
};
