import React, { HTMLAttributes } from 'react';
import Link, { LinkProps } from 'next/link';

type Props = LinkProps & {
  target?: string;
  onClick?: HTMLAttributes<HTMLAnchorElement>['onClick'];
  'data-testid'?: string;
  children?: React.ReactNode;
};

const UnderlinedLink: React.FC<Props> = ({
  children,
  target,
  onClick,
  ...props
}) => (
  <Link
    {...props}
    passHref
    prefetch={false}
    data-testid={props['data-testid']}
    target={target}
    onClick={onClick}
    className="underline hover:no-underline"
  >
    {children}
  </Link>
);

export default UnderlinedLink;
