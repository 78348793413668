import { FieldWrapper } from '@design-system';
import { ChangeEvent, forwardRef } from 'react';

export interface TextFieldProps {
  id: string;
  name: string;
  label?: string;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  className?: string;
  textAreaClassName?: string;
  disabled?: boolean;
  required?: boolean;
  rows?: number;
  warning?: string;
  showWarningMessage?: boolean;
  onChange?: (e: ChangeEvent) => void;
  onFocus?: (e: ChangeEvent) => void;
  dataTestid?: string;
}

export const TextField = forwardRef<HTMLTextAreaElement, TextFieldProps>(
  (
    {
      className,
      dataTestid,
      defaultValue,
      disabled = false,
      id,
      label,
      name,
      onChange,
      onFocus,
      placeholder,
      required = false,
      rows = 2,
      showWarningMessage,
      textAreaClassName,
      value,
      warning,
      ...rest
    },
    ref,
  ) => {
    return (
      <FieldWrapper
        $disabled={disabled}
        $error={warning}
        $id={id}
        $label={label}
        $showError={showWarningMessage}
        className={className}
      >
        <textarea
          ref={ref}
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          className={textAreaClassName}
          data-testid={dataTestid}
          defaultValue={defaultValue}
          disabled={disabled}
          id={id}
          name={name}
          onChange={onChange}
          onFocus={onFocus}
          placeholder={placeholder}
          required={required}
          rows={rows}
          value={value}
          {...rest}
        />
      </FieldWrapper>
    );
  },
);
