import classNames, { Argument } from 'classnames';
import { FC } from 'react';

interface FormHelpTextProps {
  className?: Argument;
  message?: string;
}

export const FormHelpText: FC<FormHelpTextProps> = ({
  className = '',
  message,
}) => {
  if (!message) {
    return null;
  }
  return <p className={classNames('text-neutral-4', className)}>{message}</p>;
};
