import { Alert, Button, Icon } from '@design-system';
import { CountryCode } from 'libphonenumber-js';
import { useTranslation } from 'next-i18next';
import { FC, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { InputField } from '../../../components/Forms';
import UserPreferencesContext from '../../../context/UserPreferencesContext';
import { useAuth } from '../../../providers';

interface FormValues {
  phone: string;
}

interface Props {
  error: boolean;
  loading: boolean;
  onSubmit: (data: { phone: string }) => Promise<void>;
  onSkip: () => Promise<void>;
  phone: string;
}

export const PhoneFormContainer: FC<Props> = ({
  error,
  loading,
  onSkip,
  onSubmit,
  phone,
}) => {
  const { t } = useTranslation('authentication');
  const { preferences } = useContext(UserPreferencesContext);
  const { user } = useAuth();

  const form = useForm<FormValues>({
    defaultValues: async () => {
      const lib = await import('libphonenumber-js');
      const [country, local] = phone.split('-');
      const value = `+${country}${local}`;
      if (lib.isValidPhoneNumber(value)) {
        return { phone: lib.format(phone, 'INTERNATIONAL') };
      }
      const cc = lib.getCountryCallingCode(
        (user?.countryCode || preferences.countryCode) as CountryCode,
      );
      return { phone: `+${cc}` };
    },
  });

  const input = form.watch('phone');

  useEffect(() => {
    if (input === '') {
      form.setValue('phone', '+');
    }
  }, [form, input]);

  useEffect(() => {
    form.setFocus('phone');
  }, [form]);

  return (
    <form
      className="flex flex-col gap-y-6"
      onSubmit={(ev) => void form.handleSubmit(onSubmit)(ev)}
    >
      {error && (
        <Alert $status="error" className="w-full">
          {t('errors.generic')}
        </Alert>
      )}
      <p>{t('screens.phone.subtitle')}</p>
      <InputField
        {...form.register('phone', {
          validate: async (value) => {
            const lib = await import('libphonenumber-js');
            return (
              lib.isValidPhoneNumber(value) ||
              String(t('screens.phone.phoneError'))
            );
          },
        })}
        id="phone"
        name="phone"
        prefix={<Icon name="ic_call" />}
        warning={form.formState.errors.phone?.message}
      />
      <div className="flex flex-col gap-y-3">
        <Button
          disabled={loading}
          fullWidth
          label={t('screens.profile.saveBtn')}
          size="lg"
          type="submit"
          variant="primary"
        />
        <Button
          disabled={loading}
          fullWidth
          label={t('screens.phone.skip')}
          onClick={() => void onSkip()}
          size="lg"
          type="button"
          variant="secondary"
        />
        <p className="text-center text-xs text-neutral-4">
          {t('screens.phone.phoneDisclaimer')}
        </p>
      </div>
    </form>
  );
};
