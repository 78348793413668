import { createContext } from 'react';
import { DEFAULT_USER_PREFERENCES } from '../config/UserPreferences';
import { UserPreferencesContextType } from '../types/UserPreferences';

export default createContext<UserPreferencesContextType>({
  preferences: DEFAULT_USER_PREFERENCES,
  setCountry: () => {},
  setUnitOfMeasurement: () => {},
  setCookieConsent: () => {},
});
