import { useCallback, useContext } from 'react';
import { EventOptions } from './event';
import { AnalyticsFlow, AnalyticsFlowContext } from './flow';
import { AnalyticsObject, AnalyticsObjectContext } from './object';
import { AnalyticsRoot } from './root';
import type { Map } from './types';

export interface AnalyticsEmitter {
  (
    name: string,
    action: string,
    properties?: Map,
    options?: EventOptions,
  ): void;
}

export interface UnsafeInternalEmitterOptions {
  flowOverride?: AnalyticsFlowContext;
  objectOverride?: AnalyticsObjectContext;
}

export function useAnalyticsEmitter(properties?: Map): [AnalyticsEmitter] {
  const rootContext = useContext(AnalyticsRoot);
  const flowContext = useContext(AnalyticsFlow);
  const objectContext = useContext(AnalyticsObject);

  const emit: AnalyticsEmitter = useCallback(
    (
      name: string,
      action: string,
      eventProperties?: Map,
      options?: EventOptions,
    ): void => {
      rootContext.client?.emit(
        name,
        action,
        objectContext?.name,
        flowContext?.name,
        {
          ...flowContext?.properties,
          ...objectContext?.properties,
          ...eventProperties,
          ...properties,
        },
        options,
      );
    },
    [flowContext, objectContext, rootContext],
  );

  return [emit];
}

/** This function is used inside the AnalyticsFlowProvider and AnalyticsObjectProvider
 *  components and shouldn't ever be used elsewhere. Use the `useAnalyticsEmitter`
 *  hook, or the provider render function for emitting application events.
 */
export function useUnsafeInternalEmitter(
  options: UnsafeInternalEmitterOptions,
): [AnalyticsEmitter] {
  const rootContext = useContext(AnalyticsRoot);
  const flowContext = useContext(AnalyticsFlow);
  const objectContext = useContext(AnalyticsObject);

  const emit: AnalyticsEmitter = (
    name: string,
    action: string,
    properties?: Map,
    eventOptions?: EventOptions,
  ): void => {
    rootContext.client?.emit(
      name,
      action,
      options.objectOverride?.name ?? objectContext?.name,
      options.flowOverride?.name ?? flowContext?.name,
      {
        ...(options.objectOverride?.properties ?? objectContext?.properties),
        ...(options.flowOverride?.properties ?? flowContext?.properties),
        ...properties,
      },
      eventOptions,
    );
  };

  return [emit];
}
