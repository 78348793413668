import { Icon } from '@design-system';
import classNames from 'classnames';
import React, { FC } from 'react';

interface CheckIconProps {
  checked: boolean;
  disabled: boolean;
  focus: boolean;
  shape: 'circle' | 'square';
  warning: boolean;
}

export const CheckIcon: FC<CheckIconProps> = ({
  checked,
  disabled,
  focus,
  shape,
  warning,
}) => (
  <span
    className={classNames(
      'text-white absolute border flex justify-center items-center h-6 w-6 pointer-events-none',
      'transition-colors duration-300',
      {
        'bg-dark border-dark': checked,
        'bg-light': !checked,
        'border-divider/20': !focus && !warning,
        'border-neutral-4 ring-2 ring-divider/20': focus && !warning,
        'border-orange': warning,
        'rounded-sm': shape === 'square',
        'rounded-lg': shape === 'circle',
        'bg-neutral-1': disabled,
      },
    )}
  >
    {!disabled && <Icon name="ic_check" />}
  </span>
);
