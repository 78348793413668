import React from 'react';

import { generalSubscriptionsInput } from '../../../constants/subscriptions';
import { Step, StepAnalytics } from '../../../providers';
import { GeneratedCreateSubscriptionsInput } from '../../../types/generated';
import {
  AuthFooterContainer,
  AuthMethodContainer,
  AuthTitleContainer,
  EmailContainer,
  EmailTitleContainer,
  VerifyContainer,
} from '../containers';
import { AuthAnalytics } from '../types';
// eslint-disable-next-line import/no-cycle
import { buildSignInFlow } from './buildSignInFlow';

const EMAIL_STEP = 'email';
const VERIFY_STEP = 'verify';

/**
 * Builds a sign up flow based on the provided config
 *
 * @param config.imageUrl Optional image to display on the intial step of the flow
 * @param config.properties Optional analytics properties to send throughout flow
 * @param config.title Title to display on the initial step of the flow
 * @param config.subscriptions Optional subscriptions to subscribe user to
 * @param config.hideSuccessScreen Optional boolean to hide the success screen after completion
 * @param config.showPhoneStep Optional boolean to show phone step in flow
 * @param config.optInGeneral Optional boolean to automatically opt user into general subscription
 * @returns An array of steps in the sign up flow
 */
export const buildSignUpFlow = (config?: {
  title?: string | React.ReactNode;
  imageUrl?: string;
  properties?: StepAnalytics['properties'];
  subscriptions?: GeneratedCreateSubscriptionsInput[];
  hideSuccessScreen?: boolean;
  showPhoneStep?: boolean;
  optInGeneral?: boolean;
  skipProfileStep?: boolean;
}): Step[] => {
  const {
    hideSuccessScreen = false,
    imageUrl,
    optInGeneral = true,
    properties,
    showPhoneStep = false,
    skipProfileStep,
    subscriptions,
    title,
  } = config ?? {};

  const toggleAuthFlowContainer = (
    <AuthFooterContainer buildCtaFlowSteps={() => buildSignInFlow(config)} />
  );

  const flowSteps: Step[] = [
    {
      id: 'authMethod',
      content: (
        <AuthMethodContainer
          hideSuccessScreen={hideSuccessScreen}
          imageUrl={imageUrl}
          optInGeneral={optInGeneral}
          showPhoneStep={showPhoneStep}
          subscriptions={subscriptions ?? [generalSubscriptionsInput]}
        />
      ),
      title: title ?? <AuthTitleContainer />,
      footer: toggleAuthFlowContainer,
      analytics: {
        name: AuthAnalytics.SignUp,
        properties,
      },
    },
    {
      id: EMAIL_STEP,
      content: (
        <EmailContainer stepId={EMAIL_STEP} subscriptions={subscriptions} />
      ),
      title: <EmailTitleContainer />,
      footer: toggleAuthFlowContainer,
      analytics: {
        name: AuthAnalytics.SignUpEmail,
        properties,
      },
    },
    {
      id: VERIFY_STEP,
      content: (
        <VerifyContainer
          hideSuccessScreen={hideSuccessScreen}
          optInGeneral={optInGeneral}
          showPhoneStep={showPhoneStep}
          skipProfileStep={skipProfileStep}
          stepId={VERIFY_STEP}
        />
      ),
      title: 'Verify with code',
      analytics: {
        name: AuthAnalytics.SignUpCode,
        properties,
      },
    },
  ];

  return flowSteps;
};
