import { FieldWrapper } from '@design-system';
import { forwardRef, HTMLInputTypeAttribute, ReactNode } from 'react';

export interface InputFieldProps {
  id: string;
  name: string;
  type?: HTMLInputTypeAttribute;
  label?: string;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  className?: string;
  inputClassName?: string;
  disabled?: boolean;
  required?: boolean;
  min?: string | number;
  max?: string | number;
  maxLength?: number;
  prefix?: ReactNode;
  suffix?: ReactNode;
  warning?: string;
  showWarningMessage?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  dataTestid?: string;
}

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      className,
      dataTestid,
      defaultValue,
      disabled = false,
      id,
      inputClassName = '',
      label,
      max,
      maxLength,
      min,
      name,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      placeholder,
      prefix,
      required = false,
      showWarningMessage = true,
      suffix,
      type = 'text',
      value,
      warning,
      ...rest
    },
    ref,
  ) => {
    return (
      <FieldWrapper
        $disabled={disabled}
        $error={warning}
        $id={id}
        $label={label}
        $showError={showWarningMessage}
        className={className}
      >
        {prefix && <span>{prefix}</span>}
        <input
          ref={ref}
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          className={inputClassName}
          data-testid={dataTestid}
          defaultValue={defaultValue}
          disabled={disabled}
          id={id}
          max={max}
          maxLength={maxLength}
          min={min}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          required={required}
          type={type}
          value={value}
          {...rest}
        />
        {suffix && <span>{suffix}</span>}
      </FieldWrapper>
    );
  },
);
