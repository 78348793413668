import classNames from 'classnames';
import React, { ChangeEvent, forwardRef, useState } from 'react';

import { CheckIcon } from './CheckIcon';
import { FormHelpText } from './FormHelpText';

export interface CheckInputProps {
  checked?: boolean;
  defaultChecked?: boolean;
  description?: string;
  disabled?: boolean;
  focus?: boolean;
  label: string | React.ReactNode;
  onBlur?: (ev: ChangeEvent<HTMLInputElement>) => void;
  onChange?: (ev: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (ev: ChangeEvent<HTMLInputElement>) => void;
  name: string;
  required?: boolean;
  reverse?: boolean;
  warning?: string;
  value?: HTMLInputElement['value'];
  verticalAlign?: 'top' | 'center';
}

type Props = CheckInputProps & {
  variant: 'checkbox' | 'radio';
};

export const CheckInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      checked = false,
      defaultChecked,
      description,
      disabled = false,
      focus = false,
      label,
      name,
      onBlur,
      onChange,
      onFocus,
      required,
      reverse = false,
      value,
      variant = 'checkbox',
      verticalAlign = 'top',
      warning,
    },
    ref,
  ) => {
    const [id] = useState(crypto.randomUUID());

    return (
      <label
        className={classNames('flex flex-1 gap-3', {
          'cursor-pointer': !disabled,
          'cursor-not-allowed': disabled,
          'flex-row-reverse justify-between': reverse,
          'items-center': verticalAlign === 'center',
        })}
        htmlFor={id}
      >
        <span className="flex items-center justify-center relative shrink-0 w-6 h-6">
          <input
            ref={ref}
            className="absolute opacity-0 peer"
            defaultChecked={defaultChecked}
            disabled={disabled}
            id={id}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            required={required}
            type={variant}
            value={value}
          />
          <CheckIcon
            checked={checked}
            disabled={disabled}
            focus={focus}
            shape={variant === 'checkbox' ? 'square' : 'circle'}
            warning={Boolean(warning)}
          />
        </span>
        <span
          className={classNames('break-words flex flex-col min-w-0 w-full', {
            'my-0.5': verticalAlign === 'top',
          })}
        >
          <p>{label}</p>
          <FormHelpText message={description} />
        </span>
      </label>
    );
  },
);
